.App{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
	padding: clamp(10px, 2%, 30vw) 5%;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--line-color);
  transition: background-color 1s;
}

.dark-mode-switch{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}