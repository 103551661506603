.algorithm {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	.button {
		width: 100%;
	}
	transition: 1s;
	.button.selected {
		background: linear-gradient(-10deg, #43cbff 10%, #a134c9 100%);
	}
}
.animation {
	border: 2px solid var(--line-color);
	height: 100%;
	margin: clamp(10px, 2%, 30vw) 5%;
	position: relative;
	cursor: crosshair;
	min-height: 200px !important;
}

.controls {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	.algorithm-settings,
	.drawing-settings {
		align-items: center;
	}
}
@media (max-width: 650px) {
	.controls {
		.algorithm-settings,
		.drawing-settings {
			flex-direction: column;
		}
		max-height: 30vh;
		overflow-y: auto;
	}
}

.button {
	padding: 5px 10px;
	border: 2px solid var(--line-color);
	border-radius: 0.5rem;
	cursor: pointer;
	margin: 2px 5px;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.greyed-out .button:not(.not-greyed-out) {
	opacity: 0.4;
	cursor: default;
}

.speed-label {
	cursor: default;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawing-settings,
.algorithm-settings {
	display: flex;
	position: relative;
	flex-direction: row;
	// border: 1px solid var(--line-color);
	margin: 2px 10px;
	padding: 10px 6px;
	// border-radius: 5px;
	// span{
	// 	position: absolute;
	// 	background-color: var(--background-color);
	// 	padding: 2px;
	// 	border: 1px solid var(--line-color);
	// 	top: 0;
	// 	left: 0;
	// 	transform: translateY(-100%);
	// 	border-radius: 4px;
	// }
}
